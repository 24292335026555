export default {
    createNotification(Icon, HeaderText, BodyText, CreateDate) {
        CreateDate = CreateDate || new Date();
        if (!Icon) {
            Icon = '';
        }
        let hasImage = true;
        // Switch statement for icons
        switch (Icon) {
            case 'activityAssignment': {
                HeaderText = 'Activity';
                Icon = require('@/assets/images/users/avatar-8.jpg');
                break;
            }
            case 'activityComment': {
                HeaderText = 'Comment';
                Icon = require('@/assets/images/users/avatar-8.jpg');
                break;
            }
            case 'Keep in zone': {
                hasImage = false;
                HeaderText = 'Geofence Violation';
                break;
            }
            case 'Keep out zone': {
                hasImage = false;
                HeaderText = 'Geofence Violation';
                break;
            }
            case 'Tamper': {
                hasImage = false;
                HeaderText = 'Tamper Violation';
                break;
            }
            case 'Asset Battery Disconnected': {
                hasImage = false;
                HeaderText = 'Asset Battery Disconnected';
                break;
            }
                default: {
                HeaderText = 'Message';
                Icon = require('@/assets/images/users/avatar-8.jpg');
            }
        }

        if (Icon.search('/') == -1) {
            hasImage = false;
        }
        return {
            Icon,
            showImage: hasImage,
            Header: HeaderText,
            eventText: BodyText,
            eventTime: CreateDate,
        };
    },
};
