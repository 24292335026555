import axios from '../helpers/api';
import db from '@/services/pouchDb';
import _ from 'lodash';
import helper from '../helpers/custom/index';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import moment from 'moment';
import { async } from '../libs/exceljs/exceljs.bare';

export const geoAssetAlertService = {
    getAlertActions,
    getAlertEvents,
    createAlert,
    getAssetsAlerts,
    updateAlert,
    deleteAssetAlert,
    getPeopleEmail,
};
async function getAlertActions() {
    try {
        const { data } = await axios.get('api/geo/asset-alert/alertActions');
        return data;
    } catch (error) {
        return error;
    }
}
async function getAlertEvents() {
    try {
        const { data } = await axios.get('api/geo/asset-alert/alertEvents');
        return data;
    } catch (error) {
        return error;
    }
}
async function createAlert(alert) {
    try {
        const { data } = await axios.post(`api/geo/asset-alert/${alert.entityId}`, alert);
        return data;
    } catch (error) {
        return error;
    }
}
async function updateAlert(alert) {
    try {
        const { data } = await axios.put('api/geo/asset-alert', alert);
        return data;
    } catch (error) {
        return error;
    }
}
async function deleteAssetAlert(alerts) {
    try {
        const { data } = await axios.post(`api/geo/asset-alert/delete`, alerts);
        return data;
    } catch (error) {
        console.debug('error', error);
        return error;
    }
}
async function getAssetsAlerts(assetId, page = 1, size = 100, sortField = 'lastUpdateDate', sortOrder = 'DESC', searchQuery = '') {
    try {
        const results = await axios.get(
            `api/geo/asset-alert/${assetId}?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&searchQuery=${searchQuery}`,
        );
        return results;
    } catch (error) {
        return error;
    }
}
async function getPeopleEmail(selectedPeople) {
    // fetch the peoples email
    try {
        const { data } = await axios.post('api/geo/asset-alert/peopleEmail', { people: selectedPeople });
        return data;
    } catch (error) {
        return error;
    }
}
