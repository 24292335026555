import axios from '../helpers/api';
import db from '@/services/pouchDb';
import _ from 'lodash';
import moment from 'moment';
const { v4: uuidv4 } = require('uuid');
const user = JSON.parse(localStorage.getItem('user'));
import { DateTime } from 'luxon';

export const geoDeviceService = {
    getDevices,
    getAllDevices,
    getAssetDevices,
    getUnlinkedDevices,
    getDeviceById,
    getDeviceLatestPosition,
    updateDevice,
    createDevice,
    deleteDevice,
    getDeviceTrips,
    findDevice,
    findSimIdDevice,
    getAddress,
    getDevicePermissions,
    sendCommand,
    sendCommandGetTenant,
    linkGeoFenceToDevice,
    unlinkGeoFenceFromDevice,
    getDevicePermissionsByDeviceId,
    uniqueIdExsits,
    getDeviceUsageReport,
    getDeviceFullPositionReport,
    getDeviceFullLastTripReport,
    getHardwareTypes,
    getAllActiveDevices,
    getActiveDevicesByTenant,
    getActiveAssetsByTenant,
    getDeviceStats,
    countSMSUsed,
    getAllDevicesStatus,
    getAllDevicesWithAssetStates,
    getBulkUniqueIds,
    getAllActiveDevicesDashboard,
    getActiveAssetsAndDevicesCount,
};

async function getDevices(page, size, sortField, sortOrder, search, filter, date, LastSelectedID=null) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'name';
    sortOrder = sortOrder || 'DESC';
    search = search || '';
    search = search.toUpperCase().trim();
    filter = filter || '';
    date = date || ['', ''];
    LastSelectedID = LastSelectedID || '';

    if (navigator.onLine) {
        const res = await axios.get(
            `/api/geo/device/${user.Id}?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}&filter=${filter}&date=${date[0]}&date=${date[1]}&LastSelectedID=${LastSelectedID}`,
        );
        return res.data;
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        const { rows } = await db.userDB.allDocs({
            include_docs: true,
            attachments: true,
        });
        const geoDevices = [];
        rows.forEach(row => {
            if (row.doc.mapDevice) {
                geoDevices.push(row.doc.mapDevice);
            }
        });
        return {
            result: {
                success: true,
                message: 'Geo Devices loaded and latest position info of active devices',
            },
            data: {
                geoDevices,
            },
        };
    }
}
async function getAllDevicesStatus(
    page,
    size,
    sortField = 'latest_position.deviceTime',
    sortOrder,
    search,
    startDate = null,
    endDate = null,
    deviceStatus = 'ALL',
    deviceType = 'ALL',
    deviceHardwareType = 'ALL',
    tenants,
) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'latest_position.deviceTime';
    sortOrder = sortOrder || 'DESC';
    search = search || '';
    search = search.toUpperCase().trim();
    deviceStatus = deviceStatus || '';
    deviceType = deviceType || 'ALL';
    deviceHardwareType = deviceHardwareType || 'ALL';

    if (navigator.onLine) {
        const res = await axios.get(
            `api/geo/device/reports/device-status?userId=${user.Id}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}&startDate=${startDate}&endDate=${endDate}&deviceStatus=${deviceStatus}&deviceType=${deviceType}&deviceHardwareType=${deviceHardwareType}&tenants=${tenants}`,
        );
        return res.data;
    } else {
        //ToDo
    }
}
async function getAllDevicesWithAssetStates(
    page,
    size,
    sortField = 'latest_position.deviceTime',
    sortOrder,
    search,
    startDate = null,
    endDate = null,
    deviceStatus = 'ALL',
    deviceType = 'ALL',
    deviceHardwareType = 'ALL',
    tenants,
) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'latest_position.deviceTime';
    sortOrder = sortOrder || 'DESC';
    search = search || '';
    search = search.toUpperCase().trim();
    deviceStatus = deviceStatus || '';
    deviceType = deviceType || 'ALL';
    deviceHardwareType = deviceHardwareType || 'ALL';

    if (navigator.onLine) {
        const res = await axios.get(
            `api/geo/device/reports/device-in-use?userId=${user.Id}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}&startDate=${startDate}&endDate=${endDate}&deviceStatus=${deviceStatus}&deviceType=${deviceType}&deviceHardwareType=${deviceHardwareType}&tenants=${tenants}`,
        );
        return res.data;
    } else {
        //ToDo
    }
}
async function getAllDevices(page, size) {
    page = page || 1;
    size = size || 10000;

    const res = await axios(`/api/geo/device/map/${user.Id}?page=${page}&size=${size}`);
    return res.data;
}

async function getDeviceById(id) {
    if (navigator.onLine) {
        const res = await axios(`/api/geo/device/details/${id}`);
        return res.data;
    } else {
        console.debug('add this');
        // if (!db.userDB || _.isEmpty(db.userDB)) {
        //     db.userDB = await db.refreshUserDb();
        // }
        // const { rows } = await db.userDB.allDocs({
        //     include_docs: true,
        //     attachments: true,
        // });
        // const devices = [];
        // rows.forEach(row => {
        //     if (row.doc.assetDevices) {
        //         devices.push(row.doc.assetDevices);
        //     }
        // });
        // return {
        //     result: {
        //         success: true,
        //         message:
        //             'Devices loaded and latest position info of active devices',
        //     },
        //     data: {
        //         devices,
        //     },
        // };
    }
}
async function getDeviceLatestPosition(DeviceID) {
    if (navigator.onLine) {
        const res = await axios(`/api/geo/device/details/${DeviceID}/latest-position`);
        return res.data;
    }
    //Should only work if online
    return null;
}

async function linkGeoFenceToDevice(DeviceId, GeoFenceId) {
    const request = { Id: uuidv4(), deviceId: DeviceId, geofenceId: GeoFenceId };
    const res = await axios.post(`/api/geo/permissions/`, request);
    return res.data;
}

async function unlinkGeoFenceFromDevice(DeviceId, GeoFenceId) {
    const request = {
        data: { deviceId: DeviceId, geofenceId: GeoFenceId },
    };
    const res = await axios.delete(`/api/geo/permissions/`, request);
    return res.data;
}
 
async function sendCommand(DeviceId, CommandId, messageObj) {
     
    //let tenant = listOfTenants.find(tenant => tenant.Id === currentTenant.Id);
    const res = await axios.post(`/api/geo/device/sendCommand/${DeviceId}/${CommandId}`, messageObj);
    return res.data;
}
async function sendCommandGetTenant(DeviceId,) {
    //let tenant = listOfTenants.find(tenant => tenant.Id === currentTenant.Id);
    const res = await axios.get(`/api/geo/device/sendCommand/tenant/${DeviceId}/`);
    return res.data;
}

async function getDevicePermissions(Id) {
    const res = await axios.get(`/api/geo/permissions/${Id}`);
    // console.debug('getDevicePermissions', res.data);
    return res.data;
}

async function getDevicePermissionsByDeviceId(Id) {
    const res = await axios.get(`/api/geo/permissions/device/${Id}`);
    // console.debug('getDevicePermissions', res.data);
    return res.data;
}

async function getAddress(positionId) {
    let res = await axios.get(`/api/geo/device/position/${positionId}`);

    // console.log(res.data)
    return res.data;
}

async function findDevice(search) {
    try {
        let res = await axios.get(`/api/geo/device/search/${encodeURI(search)}`);
        // console.log(res.data)
        return res.data;
    } catch (error) {
        console.debug(error);
    }
}
async function findSimIdDevice(search) {
    try {
        let res = await axios.get(`/api/geo/device/sim-search/${encodeURI(search)}`);
        // console.log(res.data)
        return res.data;
    } catch (error) {
        console.debug(error);
    }
}

async function getDeviceTrips(DeviceId, FromDate, ToDate) {
    let query = {
        deviceId: DeviceId,
        fromDate: moment(FromDate).format('YYYY-MM-DD'),
        toDate: moment(ToDate).format('YYYY-MM-DD'),
    };
    let res = await axios.get(`/api/geo/device/trip/details`, { params: query });
    console.debug(res.data);
    return res.data;
}

async function createDevice(device) {
    device['userId'] = user.Id;
    if (navigator.onLine) {
        const res = await axios.post(`/api/geo/device`, device);
        return res.data;
    } else {
        console.debug("doesn't exist");
    }
}

async function updateDevice(Id, device) {
    if (navigator.onLine) {
        const res = await axios.put(`/api/geo/device/${Id}`, device);
        return res.data;
    } else {
        console.debug("doesn't exist");
    }
}

async function deleteDevice(Id) {
    if (navigator.onLine) {
        const res = await axios.put(`/api/geo/device/delete/${Id}`);
        return res.data;
    } else {
        console.debug("doesn't exist");
    }
}

async function getAssetDevices(assetInfoId) {
    if (navigator.onLine) {
        const res = await axios(`/api/geo/asset/devices/${assetInfoId}`);
        return res.data;
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        const { rows } = await db.userDB.allDocs({
            include_docs: true,
            attachments: true,
        });
        const geoDevices = [];
        rows.forEach(row => {
            if (row.doc.assetDevices) {
                geoDevices.push(row.doc.assetDevices);
            }
        });
        return {
            result: {
                success: true,
                message: 'Geo Devices loaded and latest position info of active devices',
            },
            data: {
                geoDevices,
            },
        };
    }
}
async function getUnlinkedDevices(page, size, sortField, sortOrder, search) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';
    search = search || '';
    search = search.toUpperCase().trim();

    if (navigator.onLine) {
        const res = await axios.get(
            `/api/geo/device/unlinked-device?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
        );
        return res.data;
    } else {
        //ToDo
    }
}

async function uniqueIdExsits(uniqueId) {
    if (navigator.onLine) {
        const res = await axios.get(`/api/geo/device/exists/${uniqueId}`);
        return res.data;
    }
}

async function getDeviceUsageReport(
    deviceId,
    startDate,
    endDate,
    page,
    size,
    sortField = 'dateCreated',
    sortOrder = 'DESC',
    search = '%%',
) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();
    if (navigator.onLine) {
        try {
            const res = await axios.get(
                `/api/geo/device-asset-history/?deviceId=${deviceId}&startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
            );
            console.log(res);
            return res;
        } catch (error) {
            console.debug(error);
            return error;
        }
    } else {
        //ToDo
    }
}
async function getDeviceFullPositionReport(
    deviceId,
    startDate,
    endDate,
    page,
    size,
    sortField = 'position.deviceTime',
    sortOrder = 'ASC',
    search = '%%',
    protocolSubTypes = 'ALL',
    tenants=null,
) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'deviceTime';
    sortOrder = sortOrder || 'ASC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();
    
    if (navigator.onLine) {
        try {
            const res = await axios.get(
                `/api/geo/position/device/${deviceId}/${startDate}/${endDate}/?&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}&protocolSubTypes=${protocolSubTypes}&tenants=${tenants}`,
            );
            return res;
        } catch (error) {
            console.debug(error);
            return error;
        }
    } else {
        //ToDo
    }
}
async function getDeviceFullLastTripReport(
    deviceId,
    startDate,
    endDate,
    page,
    size,
    sortField = 'position.deviceTime',
    sortOrder = 'ASC',
    search = '%%',
    protocolSubTypes = 'ALL',
) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'deviceTime';
    sortOrder = sortOrder || 'ASC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();
    if (navigator.onLine) {
        try {
            const res = await axios.get(
                `/api/geo/position/device-last-trip/${deviceId}/${startDate}/${endDate}/?&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}&protocolSubTypes=${protocolSubTypes}`,
            );
            return res;
        } catch (error) {
            console.debug(error);
            return error;
        }
    } else {
        //ToDo
    }
}

async function getHardwareTypes(page, size, sortField, sortOrder, search) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'make';
    sortOrder = sortOrder || 'ASC';
    search = search || '%%';
    const res = await axios.get(
        `/api/geo/device-hardware?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
    );
    return res.data;
}



async function getAllActiveDevices() {
    if (navigator.onLine) {
        const res = await axios(`/api/geo/device/active`);
        return res.data;
    }
    return null;
}
async function getAllActiveDevicesDashboard(startDate, endDate) {
    if (navigator.onLine) {
        const res = await axios(`/api/geo/device/dashboard/active?startDate=${startDate}&endDate=${endDate}`);
        return res.data;
    }
    return null;
}

async function getActiveDevicesByTenant() {
    if (navigator.onLine) {
        const res = await axios(`/api/geo/device/active/by-tenant`);
        return res.data;
    }
    return null;
}
async function getActiveAssetsByTenant() {
    if (navigator.onLine) {
        const res = await axios(`/api/geo/asset/reports/active`);
        return res.data;
    }
    return null;
}

async function getDeviceStats(start, end) {
    if (navigator.onLine) {
        const res = await axios(`/api/geo/device/dashboard/stats?start=${start}&end=${end}`);
        return res.data;
    }
    return null;
}

async function countSMSUsed() {
    if (navigator.onLine) {
        const res = await axios(`/api/geo/device/dashboard/sms-used`);
        return res.data;
    }
    return null;
}

async function getBulkUniqueIds(Ids) {
    if (navigator.onLine) {
        const res = await axios.post(`/api/geo/device/unique/bulk`, { uniqueIds: Ids });
        return res.data;
    }
    return null;
}
async function getActiveAssetsAndDevicesCount(start, end) {
    if (navigator.onLine) {
        const res = await axios(`/api/geo/device/dashboard/assets?start=${start}&end=${end}`);
        return res.data;
    }
    return null;
}
