import axios from '../helpers/api';
import user from '../mixins/user';

export default {
    /* GeoFences */
    async getGeoFences(page, size, sortField, sortOrder, search) {
        page = page || 1;
        size = size || 10000;
        sortField = sortField || 'name';
        sortOrder = sortOrder || 'DESC';
        search = search || '';
        const res = await axios.get(
            `/api/geo/fences?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
        );

        return res.data;
    },
    async getGeoFencesbyTenantId(page, size, sortField, sortOrder, tenants) {
        page = page || 1;
        size = size || 10000;
        sortField = sortField || 'name';
        sortOrder = sortOrder || 'DESC';
        const res = await axios.get(
            `/api/geo/fences?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&tenants=${tenants}`,
        );
        return res.data;
    },
    async getPOIs(page, size, sortField, sortOrder, search) {
        page = page || 1;
        size = size || 10000;
        sortField = sortField || 'name';
        sortOrder = sortOrder || 'DESC';
        search = search || '';
        const res = await axios.get(
            `/api/geo/fences/poi?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
        );

        return res.data;
    },
    async getAllGeoFences() {
        const res = await axios.get(`/api/geo/fences/all`);

        // console.log(res.data)
        return res.data;
    },
    async getAllPOI() {
        const res = await axios.get(`/api/geo/fences/poi/all`);

        // console.log(res.data)
        return res.data;
    },
    async getGeoFence(GeoFenceId) {
        const res = await axios.get(`/api/geo/fences/${GeoFenceId}`);

        // console.log(res.data)
        return res.data;
    },
    async getPoi(poiId) {
        const res = await axios.get(`/api/geo/fences/poi/${poiId}`);

        // console.log(res.data)
        return res.data;
    },
    async deleteGeoFence(GeoFenceId) {
        let res = await axios.delete(`/api/geo/fences/${GeoFenceId}`);
        return res.data;
    },
    async deletePOI(POIObj) {
        let res = await axios.delete(`/api/geo/fences/poi/${POIObj}`);
        return res.data;
    },
    async updateGeoFence(GeoFenceId, GeoFenceObj) {
        const res = await axios.put(`/api/geo/fences/${GeoFenceId}`, GeoFenceObj);

        // console.log(res.data)
        return res.data;
    },
    async updatePOI(PoiId, PoiObj) {
        const res = await axios.put(`/api/geo/fences/poi/${PoiId}`, PoiObj);

        // console.log(res.data)
        return res.data;
    },
    async createGeoFence(GeoFenceObj) {
        GeoFenceObj.type = 'geofence';
        const res = await axios.post(`/api/geo/fences`, GeoFenceObj);

        // console.log(res.data)
        return res.data;
    },
    async createPOI(POIObject) {
        const res = await axios.post(`/api/geo/fences`, POIObject);

        // console.log(res.data)
        return res.data;
    },
    async getLinkedGeoFences(DeviceId) {
        const res = await axios.get(`/api/geo/fences?_dc=${1}&deviceId=${DeviceId}`); // TODO NW: what is dc????
        return res.data;
    },
};
