export const menuItems = [
    {
        label: 'Menu',
        isTitle: true,
    },
    {
        label: 'Dashboard',
        icon: 'uil-home-alt',
        /* badge: {
            variant: "primary",
            text: "01"
        }, */
        link: '/',
        permissions: 'DASHBOARD/VIEW', //removed as all users should land on the dashboard
    },
    {
        label: 'Map',
        icon: 'uil-map',
        link: '/tracking/',
        permissions: 'TRACKING/MAP',
    },
    {
        label: 'Asset Tools',
        icon: 'uil-box',
        permissions: 'TRACKING/DEVICES',
        subItems: [
            {
                label: 'Assets',
                link: '/tracking/assets',
                permissions: 'TRACKING/ASSETS',
                parentId: 5,
                subItems: [
                    {
                        label: 'Asset List',
                        link: '/tracking/assets',
                        permissions: 'TRACKING/ASSETS',
                        parentId: 5,
                    },
                    {
                        label: 'Groups',
                        link: '/tracking/assets-group',
                        permissions: 'TRACKING/ASSETS',
                        parentId: 5,
                    },
                    {
                        label: 'Categories',
                        link: '/tracking/assets-category',
                        permissions: 'TRACKING/ASSETS',
                        parentId: 5,
                    },
                ]
            },
            {
                label: 'Devices',
                link: '/tracking/device',
                permissions: 'TRACKING/DEVICES',
            },
            {
                label: 'Hardware',
                link: '/tracking/deviceHardware',
                permissions: 'TRACKING/HARDWARE',
            },
            {
                label: 'Sims',
                link: '/tracking/sims/list',
                permissions: 'TRACKING/SIMS',
            },
        ],
    },
    {
        label: 'Tracking',
        icon: 'uil-car',
        link: '/tracking',
        permissions: 'TRACKING',
        subItems: [

            {
                label: 'Trips',
                link: '/tracking/assetTrip',
                permissions: 'TRACKING/ASSETTRIPS',
            },
            {
                id: 5,
                label: 'menuitems.tracking.workbench',
                link: '/tracking/workbench',
                permissions: 'TRACKING/WORKBENCH',
            },
            // {
            //     id: 7,
            //     label: 'menuitems.tracking.notification',
            //     link: '/tracking/notification',
            //     parentId: 4,
            //     permissions: 'TRACKING/NOTIFICATIONS',
            // },
            // {
            //     label: 'Point Of Interest',
            //     link: '/tracking/poi',
            //     permissions: 'TRACKING/GEOFENCING',
            // },
            {
                label: 'Geofences',
                link: '/tracking/geofence',
                permissions: 'TRACKING/GEOFENCING',
            },
            // {
            //     label: 'Commands',
            //     link: '/tracking/command',
            //     permissions: 'TRACKING/COMMANDS',
            // },
            {
                label: 'Event IO',
                link: '/tracking/eventio',
                permissions: 'TRACKING/EVENT_IO',
                parentId: 4,
            },
            {
                label: 'Event Actions',
                link: '/tracking/eventAction',
                permissions: 'TRACKING/EVENT_ACTIONS',
                parentId: 4,
            }
        ]
    },
    {
        label: 'Reports',
        icon: 'uil-document-info',
        link: '/tracking/reports/index',
        permissions: 'TRACKING/REPORTS',
        forceIndex: true,
        subItems: [
            {
                label: 'List',
                link: '/tracking/reports/index',
                permissions: 'TRACKING/REPORTS',
            },
            {
                label: 'Schedule Report',
                link: '/tracking/schedulereport/index',
                permissions: 'TRACKING/SCHEDULE_REPORTS',
            },
            {
                label: 'Trips',
                link: '/tracking/reports/trips',
                permissions: 'TRACKING/REPORTS',
            },
            {
                label: 'Stoppages',
                link: '/tracking/reports/stoppage',
                permissions: 'TRACKING/REPORTS',
            },
            {
                label: 'Engine Run Time',
                link: '/tracking/reports/runtime',
                permissions: 'TRACKING/REPORTS',
            },
            {
                label: 'Full Telemetry',
                link: '/tracking/reports/fulltelemetry',
                permissions: 'TRACKING/REPORTS',
            },
            {
                label: 'Device Full Position',
                link: '/tracking/reports/deviceFullPosition',
                permissions: 'TRACKING/REPORTS',
            },
            {
                label: 'Device Status',
                link: '/tracking/reports/devicesstatus',
                permissions: 'TRACKING/REPORTS',
            },
            {
                label: 'Asset Status',
                link: '/tracking/reports/assetstatus',
                permissions: 'TRACKING/REPORTS',
            },
            {
                label: 'Attached Assets',
                link: '/tracking/reports/attachedassets',
                permissions: 'TRACKING/REPORTS',
            },
            {
                label: 'Devices offline Due to Battery Low',
                link: '/tracking/reports/devicesoffline',
                permissions: 'TRACKING/REPORTS',
            },
            {
                label: 'Assets In Use',
                link: '/tracking/reports/assetsinuse',
                permissions: 'TRACKING/REPORTS',
            },
            {
                label: 'Device Usage',
                link: '/tracking/reports/deviceUsage',
                permissions: 'TRACKING/REPORTS',
            },
            {
                label: 'Unit Aging Status',
                link: '/tracking/reports/unitage',
                permissions: 'TRACKING/REPORTS',
            },
            {
                label: 'Tamper',
                link: '/tracking/reports/tamper',
                permissions: 'TRACKING/REPORTS',
            },
            {
                label: 'Battery status',
                link: '/tracking/reports/battery',
                permissions: 'TRACKING/REPORTS',
            },
            {
                label: 'Geofence',
                link: '/tracking/reports/geoFence',
                permissions: 'TRACKING/REPORTS',
            },
            {
                label: 'Hot Spots',
                link: '/tracking/reports/hotspot',
                permissions: 'TRACKING/REPORTS',
            },
            {
                label: 'Specific Events',
                link: '/tracking/reports/specific_events',
                permissions: 'TRACKING/REPORTS',
            },
        ]
    },
    {
        label: 'Vehicles',
        icon: 'uil-car',
        permissions: 'VEHICLES',
        subItems: [
            {
                label: 'All vehicles',
                link: '/vehicles/list',
                permissions: 'VEHICLES',
            },
            // {
            //     label: 'Auctions',
            //     link: '/vehicles/auctions',
            //     permissions: 'VEHICLES/AUCTIONS',
            //     subItems: [
            //         {
            //             label: 'Auction Bids',
            //             link: '/vehicles/auctions/bids',
            //             permissions: 'VEHICLES/AUCTIONS',
            //         },
            //     ],
            // },
            {
                label: 'Repossessions',
                link: '/vehicles/repossessions',
                permissions: 'VEHICLES/REPOSSESSIONS',
                subItems: [
                    {
                        label: 'All Repossessions',
                        link: '/vehicles/repossessions',
                        permissions: 'VEHICLES/REPOSSESSIONS',
                    },
                    {
                        label: 'Sightings',
                        link: '/vehicles/repossessions/sightings',
                        permissions: 'VEHICLES/REPOSSESSIONS/SIGHTINGS',
                    },
                ],
            },
            {
                label: 'Spotters',
                link: '/vehicles/vehicle-spotter',
                permissions: 'VEHICLES/SPOTTERS',
                subItems: [
                    {
                        label: 'Spotters',
                        link: '/vehicles/vehicle-spotter',
                        permissions: 'VEHICLES/SPOTTERS'
                    },

                    {
                        label: 'Third party',
                        link: '/vehicles/third-party-list',
                        permissions: 'VEHICLES/3RDPARTIES',
                    },
                ],
            },
            {
                label: 'Blue Book',
                link: '/vehicles/bluebook-list',
                permissions: 'CLIENTS',
            },
        ],
    },
    {
        label: 'Administration',
        icon: 'uil-cog',
        permissions: 'ADMIN',
        hideWhenOffline: true,
        subItems: [
            {
                label: 'Users',
                permissions: 'ADMIN/USERS',
                link: '/admin/users/list',
                subItems: [
                    {
                        label: 'All Users',
                        link: '/admin/users/list',
                        permissions: 'ADMIN/USERS',
                    },
                    {
                        label: 'Deactivated Users',
                        link: '/admin/users/list-deleted',
                        permissions: 'ADMIN/USERS/DELETE',
                    },
                ],
            },
            {
                label: 'Reports',
                permissions: 'ADMIN/REPORTS',

                subItems: [
                    {
                        label: 'Email Send History',
                        link: '/tracking/reports/emailslogs',
                        permissions: 'ADMIN/REPORTS',
                    },
                    {
                        label: 'SMS Send History',
                        link: '/tracking/reports/smslogs',
                        permissions: 'ADMIN/REPORTS',
                    },
                    {
                        label: 'User Login Status',
                        link: '/tracking/reports/userLoginReport',
                        permissions: 'ADMIN/REPORTS',
                    },
                ],
            },
            {
                label: 'Roles',
                link: '/admin/roles',
                permissions: 'ADMIN/ROLES',
                subItems: [
                    {
                        label: 'All roles',
                        link: '/admin/roles/list',
                        permissions: 'ADMIN/ROLES',
                    },
                ],
            },
            {
                label: 'Templates',
                link: '/admin/template/list',
                permissions: 'ADMIN/TEMPLATES',
                subItems: [
                    {
                        label: 'All Templates',
                        link: '/admin/template/list',
                        permissions: 'ADMIN/TEMPLATES',
                    },
                ],
            },
            {
                label: 'Tenants',
                permissions: 'ADMIN/TENANTS',
                link: '/admin/tenants/list',
                subItems: [
                    {
                        label: 'Tenants',
                        link: '/admin/tenants/list',
                        permissions: 'ADMIN/TENANTS',
                    },
                    {
                        label: 'Tenant Alerts',
                        link: '/admin/tenants/alertslist',
                        permissions: 'ADMIN/TENANTS',
                        
                    },
                ],
            },
          
        ],
    },
    {
        label: 'Install',
        icon: 'uil-mobile-android',
        class: 'install-app',
        link: '#',
    }
];
